<template>
  <v-list-item class="hover:bg-[#f6f6f6]">
    <div class="flex">
      <v-list-item-title class="flex grow">
        <label
          :aria-label="label"
          :for="`#${label}`"
          class="w-full cursor-pointer text-neutral-500"
          @click="showMenu = !showMenu"
        >
          {{ label }}
        </label>
      </v-list-item-title>

      <UiSelect
        :id="label"
        v-model:menu="showMenu"
        v-model="modelValue"
        class="-mt-1 ml-auto max-w-40"
        v-bind="$attrs"
        :items="items"
        density="compact"
        :item-title="itemTitleLocal"
        :item-value="itemValueLocal"
        :track-event
      />
    </div>
  </v-list-item>
</template>

<script lang="ts" setup generic="Item extends Dictionary">
import UiSelect from "../Ui/UiSelect.vue";

const props = withDefaults(
  defineProps<{
    items: Item[] | readonly Item[];
    label: string;
    itemTitle?: keyof Item | "title";
    itemValue?: keyof Item | "value";
    trackEvent: TrackEventProperties | undefined;
  }>(),
  {
    itemTitle: "title",
    itemValue: "value",
  }
);

const itemTitleLocal = computed(() => String(props.itemTitle));
const itemValueLocal = computed(() => String(props.itemValue));

const showMenu = ref(false);

const modelValue = defineModel<any>({
  required: true,
});
</script>
