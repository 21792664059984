export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) {
    if (to.path === "/logout") {
      const logoutURL = await $fetch("/auth/logout");
      return navigateTo(logoutURL, { external: true });
    }

    if (!useAuth()?.loggedIn) {
      // Do not set next cookie if its already present (redirected from django or admin-ui)
      const cookies = document.cookie.split(";");
      const next = cookies.some((e) => e.trim().startsWith("next"))
        ? null
        : location.href;

      const loginURL = await $fetch("/auth/login", {
        params: { next },
      });
      return navigateTo(loginURL, { external: true });
    }
  }
});
