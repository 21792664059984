import payload_plugin_qLmFnukI99 from "/home/runner/work/dashboard/dashboard/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/dashboard/dashboard/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/dashboard/dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_IJZcPvBsSf from "/home/runner/work/dashboard/dashboard/node_modules/nuxt-highcharts/lib/plugin.js";
import plugin_8IPS7oQSrt from "/home/runner/work/dashboard/dashboard/node_modules/@nuxtjs/kinde/dist/runtime/plugin.js";
import navigationTracking_client_zNlObAw3Pm from "/home/runner/work/dashboard/dashboard/plugins/navigationTracking.client.ts";
import resizeObserver_dxeNF6Qd93 from "/home/runner/work/dashboard/dashboard/plugins/resizeObserver.ts";
import saneHtml_oy2dAsT01l from "/home/runner/work/dashboard/dashboard/plugins/saneHtml.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/dashboard/dashboard/plugins/sentry.client.ts";
import tooltip_Di9mlKVbnI from "/home/runner/work/dashboard/dashboard/plugins/tooltip.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/dashboard/dashboard/plugins/vuetify.ts";
import webComponents_client_4yPnsyzXLG from "/home/runner/work/dashboard/dashboard/plugins/webComponents.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_IJZcPvBsSf,
  plugin_8IPS7oQSrt,
  navigationTracking_client_zNlObAw3Pm,
  resizeObserver_dxeNF6Qd93,
  saneHtml_oy2dAsT01l,
  sentry_client_shVUlIjFLk,
  tooltip_Di9mlKVbnI,
  vuetify_7h9QAQEssH,
  webComponents_client_4yPnsyzXLG
]