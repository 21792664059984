<template>
  <v-list-item class="">
    <div class="flex">
      <v-list-item-title class="flex grow">
        <label
          :aria-label="label"
          :for="`#${label}`"
          class="w-full cursor-pointer text-neutral-500"
          @click="showMenu = !showMenu"
        >
          {{ label }}
        </label>
      </v-list-item-title>

      <v-select
        v-model="modelValue"
        v-model:menu="showMenu"
        :items="items"
        class="-mt-2 ml-auto max-w-40"
        multiple
        :menu-props="{ contentClass: 'multi-select-content' }"
        v-bind="$attrs"
        density="comfortable"
        :placeholder="`0 / ${items.length}`"
      >
        <template #selection="{ index }">
          <span
            v-if="!index"
            class="text-sm"
          >
            {{ modelValue.length }} / {{ items.length }}
          </span>
        </template>
      </v-select>
    </div>
  </v-list-item>
</template>

<script setup lang="ts">
const props = defineProps<{
  label: string;
  items: (string | { title: string; value: string })[];
  trackEvent: TrackEventProperties | undefined;
}>();

const showMenu = ref(false);

const modelValue = defineModel<string[]>({ required: true });

if (props.trackEvent) {
  useTrackEvent().watch({
    value: modelValue,
    ...props.trackEvent,
  });
}
</script>

<style>
.multi-select input::placeholder {
  font-size: 0.875rem !important;
}
.multi-select-content .v-list-item {
  padding-left: 0.25rem !important;
  font-size: 0.75rem;
}
.multi-select-content .v-list-item-title {
  font-size: 0.75rem !important;
}
</style>
