<template>
  <v-list-item
    :ripple="false"
    class="checkbox-select-item py-2 hover:bg-gray-100"
    :disabled
    @click.capture="emit('select', !modelValue, $event)"
    @keydown.enter.capture="emit('select', !modelValue, $event)"
  >
    <label class="pointer-events-none flex items-center justify-start">
      <v-checkbox-btn
        v-if="!hideCheckbox"
        :model-value
        :indeterminate="!modelValue && indeterminate"
        :class="['ml-3 size-6 grow-0', { 'self-start': description }]"
        :color
        tabindex="-1"
      />

      <div class="ml-4 text-xs">
        <span class="checkbox-select-item__title">{{ title }}</span>
        <span
          v-if="description"
          v-sane-html="description"
          class="checkbox-select-item__description block text-gray-500"
        />
      </div>
    </label>
  </v-list-item>
</template>

<script setup lang="ts">
defineProps<{
  modelValue: boolean;
  title: string;
  description?: string | null;
  color?: string;
  hideCheckbox?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  select: [boolean, KeyboardEvent | MouseEvent];
}>();
</script>
