// Required explicit import due to use of util on server
import { isNumber } from "./isNumber";

type Input = number | null | undefined;

/** Sums all the elements of an array. Defaults to intialising with zero, but can be initialised with any number or null */
function sum(arr: Input[]): number;
function sum(arr: Input[], initial: number | null): number | null;
function sum(arr: Input[], initial: number | null = 0) {
  let output = initial;

  for (const value of arr) {
    if (isNumber(value)) {
      output = Number(output) + Number(value);
    }
  }

  return output;
}

export { sum };
