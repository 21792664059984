// requires explicit import so that util can be used on server
import { isNumber } from "./isNumber";

export function round<T extends number | null | undefined>(
  value: T,
  digits = 0
): T {
  if (!isNumber(value)) return value;

  const { format } = Intl.NumberFormat("en-US", {
    maximumFractionDigits: digits,
    useGrouping: false,
  });

  return +format(value) as T;
}
