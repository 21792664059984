<template>
  <v-list-item
    :ripple="false"
    @click="modelValue = !modelValue"
  >
    <div class="flex justify-between align-middle">
      <v-list-item-title
        class="flex max-h-10 min-w-[180px] grow basis-full cursor-pointer items-center"
        @click="modelValue = !modelValue"
      >
        <label
          :aria-label="label"
          :for="`#${label}`"
          class="w-full cursor-pointer text-neutral-500"
          @click="modelValue = !modelValue"
        >
          {{ label }}
        </label>
      </v-list-item-title>

      <UiCheckboxBtn
        v-bind="$attrs"
        v-model="modelValue"
        density="compact"
        :ripple="false"
        :track-event
        class="max-h-10"
        color="primary"
      >
      </UiCheckboxBtn>
    </div>
  </v-list-item>
</template>

<script lang="ts" setup>
defineProps<{
  label: string;
  trackEvent: TrackEventProperties | undefined;
}>();

const modelValue = defineModel<boolean>({ required: true });
</script>

<style scoped>
:deep(.v-selection-control) {
  flex-direction: row-reverse;
  justify-content: space-between !important;
}

:deep(.v-selection-control .v-label) {
  padding-left: 0;
}
</style>
