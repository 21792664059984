import { captureException, captureMessage } from "@sentry/vue";

import { useSummaryStore } from "~/components/Analysis/Overview/useSummary";
import type { StockLoanBenchmarkingPercentiles } from "~/models/stock-loan-benchmarking-percentiles";
import type { SummaryInfo } from "~/models/summary-info";
import type { Tiers } from "~/models/tiers";
import type { FxData } from "~/server/routes/api-v2/fx-rates";
import { useAppStore } from "~/store/app";
import { useUserSettingsStore } from "~/store/userSettings";

function handleMiddlewareError(e: Response) {
  if (e.status === 401 || e.status === 403) {
    location.assign("/logout");
    return;
  }

  const message = `
    There was an error during the setup of the application
    Please try again or contact the system admin if the problem persists
  `
    .trim()
    .replaceAll(/ {2,}/g, "");

  throw showError({ data: e, message });
}

async function requestMeta() {
  const appStore = useAppStore();

  if (appStore.envMeta.client) return;

  return $fetch("/api/meta/", {
    ignoreResponseError: true,
    onResponse: (e) => {
      if (!e.response.ok) {
        handleMiddlewareError(e.response);
        return;
      }

      appStore.envMeta = e.response._data;

      const { environment, version, release_timestamp } = appStore.envMeta;
      const timestampIso = toISOstring(UTC(release_timestamp));

      printFormattedMessage([
        {
          text: "Zeus",
          style: `background-color: ${theme.primary}; color: white; padding-right: 0px !important;`,
        },
        {
          text: `- Environment: ${environment} - Version: ${version} - Timestamp: ${timestampIso}`,
          style: `background-color: ${theme.primary}; color: white;`,
        },
      ]);
    },
  });
}

async function requestProfile() {
  const appStore = useAppStore();

  if (appStore.userProfile) return;

  return $fetch("/api/profile/", {
    ignoreResponseError: true,
    onResponse: (e) => {
      if (!e.response.ok) {
        handleMiddlewareError(e.response);
        return;
      }

      appStore.userProfile = e.response._data;
      appStore.isSuperuserOverride = e.response._data.is_superuser;
      appStore.isStaffOverride = e.response._data.is_staff;
      appStore.subscriptions = e.response._data.subscriptions;
    },
  });
}

async function requestUserSettings() {
  const userSettingsStore = useUserSettingsStore();
  const { userProfile } = useAppStore();

  if (userSettingsStore.initialised || !userProfile?.email) return;

  return $fetch<Dictionary>("/api-v2/user-settings", {
    query: { email: userProfile.email },
    ignoreResponseError: true,
    onResponse: (e) => {
      checkResponseForErrors(e);

      // TODO: move exception capturing to server
      if (!e.response.ok) {
        captureException(
          `Failed to fetch user settings for user ${userProfile.email} with status ${e.response.status}`
        );
        return;
      }

      userSettingsStore.data = e.response._data;
      userSettingsStore.initialised = true;

      migrateLocalSettingsToDynamo();
    },
  });
}

async function requestSummaryInfo() {
  const appStore = useAppStore();

  if (appStore.summaryInfo.length) return;

  return $fetch<SummaryInfo[]>("/api/summary-info/", {
    ignoreResponseError: true,
    onResponse: (e) => {
      if (!e.response.ok) {
        handleMiddlewareError(e.response);
      }

      appStore.summaryInfo = e.response._data;
    },
  });
}

async function requestAccounts() {
  const appStore = useAppStore();

  if (appStore.accounts.length) return;

  return $fetch("/api/accounts/", {
    ignoreResponseError: true,
    onResponse: (e) => {
      if (!e.response.ok) {
        handleMiddlewareError(e.response);
        return;
      }

      appStore.accounts = e.response._data;
    },
  });
}

async function requestFxRates() {
  const summaryStore = useSummaryStore();

  if (summaryStore.fxRates) return;

  return $fetch<FxData>("/api-v2/fx-rates", {
    ignoreResponseError: true,
    onResponse: (e) => {
      if (!e.response.ok) {
        handleMiddlewareError(e.response);
      }

      summaryStore.fxRates = e.response._data;
    },
  });
}

async function requestStrategyAccounts() {
  const appStore = useAppStore();

  if (appStore.strategyAccounts.length) return;

  appStore.pendingRequests.add("/api/strategies/accounts");

  return $fetch("/api/strategies/accounts/", {
    ignoreResponseError: true,
    onResponse: async (e) => {
      checkResponseForErrors(e);

      if (e.response.ok) {
        const data: StrategyAccount[] = e.response._data ?? [];
        appStore.strategyAccounts = data.filter(
          (e) => e.title && e.strategy.title
        );

        if (appStore.strategyAccounts.length !== data.length) {
          captureMessage(
            "Some strategy accounts are missing title or strategy title"
          );
        }
      }

      appStore.pendingRequests.delete("/api/strategies/accounts");
    },
  });
}
async function requestStrategyAccountsDemo() {
  const appStore = useAppStore();

  if (appStore.strategyAccounts.length) return;

  appStore.pendingRequests.add("/api/strategies/accounts");

  return $fetch("/api-v2/strategies/accounts/", {
    ignoreResponseError: true,
    onResponse: (e) => {
      checkResponseForErrors(e);

      if (e.response.ok) {
        appStore.strategyAccounts = e.response._data;
      }

      appStore.pendingRequests.delete("/api/strategies/accounts");
    },
  });
}

async function requestTiers() {
  const appStore = useAppStore();

  if (appStore.tiers) return;

  return $fetch<Tiers>("/api/tiers/", {
    ignoreResponseError: true,
    onResponse: (e) => {
      checkResponseForErrors(e);

      if (e.response.ok) {
        appStore.tiers = e.response._data;
      }
    },
  });
}

async function requestDispersionPercentiles() {
  const appStore = useAppStore();

  if (appStore.dispersionPercentiles) return;

  return $fetch<StockLoanBenchmarkingPercentiles>(
    "/api/stock-loan-benchmarking-percentiles/",
    {
      ignoreResponseError: true,
      onResponse: (e) => {
        checkResponseForErrors(e);

        if (e.response.ok) {
          appStore.dispersionPercentiles = e.response._data;
        }
      },
    }
  );
}

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.client) {
    const requiredRequests = [
      requestMeta(),
      requestProfile().then(requestUserSettings),
      requestAccounts(),
    ];

    requestSummaryInfo();
    requestFxRates();
    requestDispersionPercentiles();
    requestTiers();
    requestStrategyAccounts().then(requestStrategyAccountsDemo);

    // Required for authorization middleware
    await Promise.all(requiredRequests);
  }
});
