import type { Account } from "@/models";

import { useAppStore } from "./app";
import type { DateStoreDefinitionSetup } from "./date.types";
import { type DateQuickSelectOption, useDate } from "./useDate";

export type UseDateAppStore = typeof useDateAppStore;

export const useDateAppStore: DateStoreDefinitionSetup = defineStore(
  "dateApp",
  () => {
    const { accounts, defaultDateRange } = storeToRefs(useAppStore());

    const yesterday = UTC().subtract(1, "day");

    const {
      startDate,
      endDate,
      selectedDate,
      cachedDateRange,
      setPeriod,
      getQuickSelectOptions,
    } = useDate();

    startDate.value = toISOstring(yesterday);
    endDate.value = toISOstring(yesterday);
    selectedDate.value = toISOstring(yesterday);

    const inceptionDate = computed<string>(
      () =>
        accounts.value
          .map((e) => e.start_date)
          .filter(Boolean)
          .sort((a, b) => +UTC(a) - +UTC(b))
          .at(0) ?? toISOstring(yesterday)
    );

    /*
     * Latest date any pipeline has data.
     *  - Defaults to yesterday if no data received
     *  - Cannot display todays date
     */

    function accountPipelineHasRunOnDate(date: string) {
      return (account: Account) =>
        // Account is active on date
        +UTC(account.end_date ?? maxSafeDateMs) >= +UTC(date) &&
        // Account has run pipeline
        !account.missing_dates.includes(date);
    }

    const latestDate = computed<string>(() => {
      const dateRange = createDateRangeArray(inceptionDate.value, yesterday);

      const latestAccountDate = dateRange.findLast((date) =>
        accounts.value.find(accountPipelineHasRunOnDate(date))
      );

      return latestAccountDate ?? endDate.value;
    });

    function setDatesToLatest(quickSelectOptions: DateQuickSelectOption[]) {
      const defaultOption = [
        UTC(latestDate.value).subtract(364, "days"),
        UTC(latestDate.value),
      ];

      const selectedOption = quickSelectOptions.find(
        (e) => e.title === defaultDateRange.value
      );

      const [defaultStart, defaultEnd] = selectedOption?.value ?? defaultOption;

      const startDateMS = Math.max(+defaultStart, +UTC(inceptionDate.value));

      startDate.value = toISOstring(UTC(startDateMS));
      endDate.value = toISOstring(defaultEnd);
      selectedDate.value = toISOstring(defaultEnd);

      if (import.meta.env.VITE_SELECTED_DATE) {
        selectedDate.value = import.meta.env.VITE_SELECTED_DATE;
      }
      if (import.meta.env.VITE_START_DATE) {
        startDate.value = import.meta.env.VITE_START_DATE;
      }
      if (import.meta.env.VITE_END_DATE) {
        endDate.value = import.meta.env.VITE_END_DATE;
      }
    }

    const dateQuickSelectOptions = getQuickSelectOptions(
      inceptionDate,
      latestDate
    );

    watch(dateQuickSelectOptions, setDatesToLatest, { immediate: true });

    const allowedDateMinMs = computed(() => +UTC(inceptionDate.value));
    const allowedDateMaxMs = computed(() => +UTC(latestDate.value));

    function isDisabledDate(date: Date) {
      const dateMs = +UTC(date).startOf("day");
      return dateMs < allowedDateMinMs.value || dateMs > allowedDateMaxMs.value;
    }

    return {
      allowedDateMax: latestDate,
      allowedDateMin: inceptionDate,
      cachedDateRange,
      endDate,
      selectedDate,
      startDate,
      isDisabledDate,
      setPeriod,
    };
  }
);
