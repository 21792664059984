import { captureMessage } from "@sentry/vue";

/** csrf token needs to be manually included on destructive requests */
export const createHeaders = (config?: RequestInit) => {
  const token = useCookie("csrftoken");

  const headers = new Headers({
    "X-CSRFToken": token.value ?? "",
  });

  if (config?.headers) {
    Object.entries(config.headers).forEach((header) => headers.set(...header));
  }

  return headers;
};

/** Adds query parameters to a url */
export function addSearchParams(url: string, params: Dictionary): string {
  const p = Object.entries(params)
    // Sort keys so always in same order for caching
    .sort(([a], [b]) => a.localeCompare(b))
    // Sort values in arrays so always in same order for caching
    .map(([key, val]) => [key, [val].flat().sort().join(",")]);

  if (p.length === 0) return url;

  return `${url}?${new URLSearchParams(p)}`
    .replace(/%2C/g, ",")
    .replace(/%3A/g, ":");
}

// Temporary mapping until backend values have been updated.
// ADO-7232 23.02.24
export function mapValues<T>(raw: T): T {
  if (
    /Stock (L|l)oan/g.test(JSON.stringify(raw)) ||
    /Short Sale Proceeds/g.test(JSON.stringify(raw))
  ) {
    captureMessage("Mapping values for api results");

    const str = JSON.stringify(raw)
      .replaceAll(/Stock (L|l)oan/g, "Borrow")
      .replaceAll("Short Sale Proceeds", "Borrow Cash Collateral");

    return JSON.parse(str) as T;
  }

  return raw;
}

export async function checkResponseForErrors(e: {
  response: Response;
  error?: Error;
}) {
  // Navigating to logout triggers full clearing of cookies
  if (e.response.status === 403 || e.response.status === 401) {
    document.cookie = `next=${location.href}`;
    await navigateTo("/logout");
    return;
  }

  if (!e.response.ok || e.error) {
    captureMessage(
      e.error
        ? JSON.stringify(e.error)
        : `${e.response.status}: ${e.response.statusText}`
    );

    const content = `
    An error occured at: ${e.response.url.split("?")[0]}
    <br>
    ${e.response.status}: ${e.response.statusText}
    <br>
    ${e.error ?? ""}
    `;
    GlobalBus.$emit("snackbar:error", content);
  }
}
