import { captureMessage } from "@sentry/vue";

import type { DataSeries } from "#build/imports";
import type { ChartSeries } from "~/components/_App/HighchartsLine.vue";
import { CurrencyCell } from "~/components/DataTable/Cell/DataTableCellCurrency.vue";
import type { FxData } from "~/server/routes/api-v2/fx-rates";
import { useAppStore } from "~/store/app";
import { precisionTypes } from "~/store/app.types";
import { useDateAppStore } from "~/store/dateApp";

export type SummaryResponse = {
  prime_broker?: string;
  fund?: string;
  account?: string;
  currency?: string;
  field?: string;
  data: (number | null)[];
};

export type SummaryData = SummaryResponse & {
  baseData: (number | null)[];
};

type UseSummaryParams = {
  summaryResponse: Ref<SummaryResponse[] | null>;
  selectedDataSeries: Ref<DataSeries>;
  showTotalLine: Ref<boolean>;
  isCumulative?: Ref<boolean>;
};

export const useSummaryStore = defineStore("summary", () => {
  const { selectedView, splitBy, globalCurrency, showPositionCurrency } =
    storeToRefs(useAppStore());
  const { startDate, endDate } = storeToRefs(useDateAppStore());

  const dateRangeMs = computed(() =>
    createDateMsRangeArray(startDate.value, endDate.value)
  );

  const fxRates = ref<FxData>();

  const viewColumn = computed(() => selectedView.value.groupBy ?? "field");
  const splitColumn = computed(() => splitBy.value ?? "field");

  function createLineData(items: SummaryData[]) {
    const isSingleCurrency = globalCurrency.value !== "USD";

    const dataKey =
      showPositionCurrency.value && isSingleCurrency ? "data" : "baseData";

    return dateRangeMs.value.map((date, i) => {
      const zipped = items.map((e) => e[dataKey][i]);

      return Tuple([date, sum(zipped, null)]);
    });
  }

  return {
    dateRangeMs,
    viewColumn,
    splitColumn,
    fxRates,
    createLineData,
  };
});

export function useSummary({
  summaryResponse,
  selectedDataSeries,
  showTotalLine,
  isCumulative,
}: UseSummaryParams) {
  const { globalCurrency } = storeToRefs(useAppStore());
  const { selectedDate } = storeToRefs(useDateAppStore());
  const { dateRangeMs, viewColumn, splitColumn, fxRates } =
    storeToRefs(useSummaryStore());
  const { createLineData } = useSummaryStore();

  const dataFields = computed(() => {
    const fieldView = [viewColumn.value, splitColumn.value].includes("field");
    const dataSeriesChildren = selectedDataSeries.value.children;

    if (fieldView && dataSeriesChildren.length) {
      return dataSeriesChildren;
    }

    return [selectedDataSeries.value];
  });

  function mapToTitle(value: unknown) {
    const item = dataFields.value.find((e) => e.value?.field === value);

    return item?.title ?? String(value);
  }

  function getItemColor(name: string) {
    const index = dataFields.value.findIndex((e) => e.value?.field === name);

    if (index >= 0) {
      return (
        dataFields.value[index].color ??
        categoryColors[index % categoryColors.length]
      );
    }

    return useAppStore().getItemColor(name);
  }

  // Add base currency data to respnose
  const summaryData = computed(() => {
    return summaryResponse.value?.map((e): SummaryData => {
      const currency = e.currency ?? globalCurrency.value;

      const baseData = e.data.map((e, i) => {
        if (!isNumber(e) || !fxRates.value) {
          return null;
        }

        const date = toISOstring(dateRangeMs.value[i]);
        const rate = fxRates.value[date]?.[currency];

        if (!rate) {
          captureMessage(`No fx rate found for: ${date}, ${currency}`);
          return null;
        }

        return e / rate;
      });

      return { ...e, baseData };
    });
  });

  const historicalData = computed<ChartSeries[]>(() => {
    if (!summaryData.value) return [];

    const grouped = groupByKey(summaryData.value, viewColumn.value);

    const historicalData = objectEntries(grouped).flatMap(([name, items]) => {
      const parentLine: ChartSeries = {
        name: mapToTitle(name),
        type: "line",
        data: createLineData(items),
        color: getItemColor(name),
      };

      const childSeries = items.map(
        (e): ChartSeries => ({
          name: mapToTitle(e[splitColumn.value]!),
          type: "area",
          data: createLineData([e]),
          color: getItemColor(e[splitColumn.value]!),
          custom: { parent: mapToTitle(e[viewColumn.value]!) },
          opacity: 0.5,
        })
      );

      return [parentLine, ...childSeries];
    });

    if (showTotalLine.value && Object.keys(grouped).length > 1) {
      const totalName =
        viewColumn.value === "field" ? selectedDataSeries.value.title : "Total";

      const totalLine: ChartSeries = {
        name: totalName,
        type: "line",
        data: createLineData(summaryData.value),
        color: theme.accent,
      };

      const grouped = groupByKey(summaryData.value, splitColumn.value);

      const childSeries = objectEntries(grouped).map(
        ([name, items]): ChartSeries => ({
          name: mapToTitle(name),
          type: "area",
          data: createLineData(items),
          color: getItemColor(name),
          custom: { parent: totalName },
          opacity: 0.5,
        })
      );

      historicalData.push(totalLine, ...childSeries);
    }

    return historicalData;
  });

  const roundingDigits = precisionTypes.accrual[1];

  const dailyData = computed(() => {
    if (!summaryData.value) return;

    const selectedDateIndex = dateRangeMs.value.indexOf(
      +UTC(selectedDate.value)
    );
    const startDateIndex = isCumulative?.value ? 0 : selectedDateIndex;

    const filteredData: SummaryData[] = summaryData.value.map((e) => ({
      ...e,
      data: e.data.slice(startDateIndex, selectedDateIndex + 1),
      baseData: e.baseData.slice(startDateIndex, selectedDateIndex + 1),
    }));

    const grouped = groupByKey(filteredData, viewColumn.value);

    const dailyData = Object.entries(grouped).map(([group, items]) => {
      const filteredData = items.map(({ data, baseData, ...e }) => {
        const currency = e.currency ?? globalCurrency.value;
        const local = round(sum(data, null), roundingDigits);
        const base = round(sum(baseData, null), roundingDigits);

        return { ...e, currency, base, local };
      });

      const currencies = unique(filteredData.map((e) => e.currency));

      const totalBase = round(
        sum(
          filteredData.map((e) => e.base),
          null
        ),
        roundingDigits
      );
      const totalLocal = isUnique(currencies)
        ? round(
            sum(
              filteredData.map((e) => e.local),
              null
            ),
            roundingDigits
          )
        : totalBase;

      const item: Dictionary = {
        [viewColumn.value]: mapToTitle(group),
        Total: new CurrencyCell({
          currency: isUnique(currencies) ? currencies[0] : "USD",
          base: totalBase ?? null,
          local: totalLocal ?? null,
        }),
        color: getItemColor(group),
      };

      if (splitColumn.value !== viewColumn.value) {
        filteredData.forEach((e) => {
          item[e[splitColumn.value]!] = new CurrencyCell(
            pick(e, ["currency", "base", "local"])
          );
        });
      }

      if (
        viewColumn.value === "field" &&
        !selectedDataSeries.value.children.length
      ) {
        item.isTotal = true;
      }

      return item;
    });

    return dailyData;
  });

  const columnData = computed(() => {
    if (!summaryData.value) return;

    const selectedDateIndex = dateRangeMs.value.indexOf(
      +UTC(selectedDate.value)
    );

    const filteredData = summaryData.value.map(({ data, baseData, ...e }) => ({
      [viewColumn.value]: mapToTitle(e[viewColumn.value]),
      [splitColumn.value]: mapToTitle(e[splitColumn.value]),
      localValue: data[selectedDateIndex],
      baseValue: baseData[selectedDateIndex],
    }));

    return filteredData.filter((e) => e.localValue);
  });

  const splits = computed(() => {
    const values = summaryResponse.value?.map((e) => e[splitColumn.value]);

    // Make sure to filter out undefined as the computed will fire before the data is refreshed
    const uniqueValues = unique(values ?? [])
      .filter((e): e is string => Boolean(e))
      .sort();

    if (splitColumn.value === "field") {
      const fields = dataFields.value.map((e) => e.value?.field);

      uniqueValues.sort((a, b) => fields.indexOf(a) - fields.indexOf(b));
    }

    return uniqueValues.map((value) => ({ title: mapToTitle(value), value }));
  });

  const enableTotalRowLayers = computed(
    () =>
      showTotalLine.value ||
      (viewColumn.value === "field" && dataFields.value.length === 1)
  );

  return {
    historicalData,
    dailyData,
    columnData,
    splits,
    enableTotalRowLayers,
  };
}
