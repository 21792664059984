/**
 * Groups an array of objects by a key, returning a record of the grouped objects.
 */
export function groupByKey<T, Key extends string>(
  array: T[] | readonly T[],
  key: keyof T | ((el: T) => Key)
) {
  const result = {} as Record<Key, T[]>;

  array.forEach((item) => {
    const property = typeof key === "function" ? key(item) : (item[key] as Key);

    result[property] ??= [];
    result[property].push(item);
  });

  return result;
}
/**
 * Groups an array of objects by a key, returning a map of the grouped objects.
 * Useful for preserving order of insertion.
 */
export function groupByKeyMap<T, Key>(
  array: T[] | readonly T[],
  key: keyof T | ((el: T) => Key)
) {
  const result = new Map<Key, T[]>();

  array.forEach((item) => {
    const property = typeof key === "function" ? key(item) : (item[key] as Key);
    const current = result.get(property);
    if (current) {
      current.push(item);
    } else {
      result.set(property, [item]);
    }
  });

  return result;
}
