<template>
  <v-card
    class="pb-2"
    width="445px"
  >
    <v-list
      v-model:opened="openedGroup"
      density="compact"
      open-strategy="single"
    >
      <AppSettingsDebugMenu v-if="userProfile?.is_superuser" />

      <template v-if="route.meta.sectionKeys">
        <v-list-item @click="handleSaveViewClick">
          <div class="flex items-center justify-between">
            <v-list-item-title>
              Save as custom page settings
            </v-list-item-title>
            <v-icon
              class="mr-1"
              size="small"
              :icon="viewSaved ? mdiBookmark : mdiBookmarkOutline"
            />
          </div>
        </v-list-item>
        <v-list-item
          :disabled="!pageHasSavedSettings"
          @click="removeSavedView"
        >
          <div class="flex items-center justify-between">
            <v-list-item-title>
              Restore to default page settings
            </v-list-item-title>
            <v-icon
              class="mr-1"
              size="small"
              :icon="mdiBookmarkRemove"
            />
          </div>
        </v-list-item>
        <hr class="m-2" />
      </template>

      <v-list-item class="mb-2 text-xs text-neutral-500">
        Global Settings
      </v-list-item>

      <AppSettingsCheckbox
        v-model="showPositionCurrency"
        label="Show position currency"
        :track-event="{ name: 'Show Position Currency' }"
      />
      <AppSettingsSelect
        v-model="tiering"
        label="Tiering"
        :track-event="{ name: 'Tiering' }"
        :items="[
          { value: 'kst', title: 'Kayenta' },
          { value: 'prime_broker', title: 'Prime Broker' },
        ]"
      />
      <AppSettingsCheckbox
        v-model="zoomYAxis"
        label="Zoom y-axis"
        :track-event="{ name: 'Zoom Y-Axis' }"
      />
      <AppSettingsCheckbox
        v-model="isMultisort"
        label="Multisort table"
        :track-event="{ name: 'Multisort Table' }"
      />
      <AppSettingsCheckbox
        v-model="showPrecision"
        label="Show precision"
        :track-event="{ name: 'Show Precision' }"
      />
      <AppSettingsSelect
        v-model="graphSmoothing"
        label="Line chart smoothing"
        :track-event="{ name: 'Graph smoothing' }"
        :items="[
          { value: 1, title: 'None' },
          { value: 7, title: '7 Days' },
          { value: 30, title: '30 Days' },
          { value: 90, title: '90 Days' },
        ]"
      />

      <hr class="m-2" />

      <NuxtLink
        to="/logout"
        external
      >
        <v-list-item>
          <div class="flex items-center justify-between">
            <v-list-item-title> Logout </v-list-item-title>
            <v-icon
              class="mr-1"
              size="small"
              :icon="mdiLogoutVariant"
            />
          </div>
        </v-list-item>
      </NuxtLink>
    </v-list>
  </v-card>
</template>

<script setup lang="ts">
import {
  mdiBookmark,
  mdiBookmarkOutline,
  mdiBookmarkRemove,
  mdiLogoutVariant,
} from "@mdi/js";

import { useAppStore } from "@/store/app";
import { useUserSettingsStore } from "~/store/userSettings";

const {
  userProfile,
  showPositionCurrency,
  tiering,
  zoomYAxis,
  isMultisort,
  showPrecision,
  graphSmoothing,
} = storeToRefs(useAppStore());

const userSettings = useUserSettingsStore();

const openedGroup = ref(["tableAndChartSettings"]);

const viewSaved = ref(false);

const trackEvent = useTrackEvent();

function handleSaveViewClick() {
  GlobalBus.$emit("save-view");
  viewSaved.value = true;
  pageHasSavedSettings.value = true;

  trackEvent.trigger({
    name: "Save Default Page View",
  });
}

function removeSavedView() {
  GlobalBus.$emit("clear-view");
  pageHasSavedSettings.value = false;

  trackEvent.trigger({
    name: "Remove Saved Page View",
  });
}

const route = useRoute();

const pageHasSavedSettings = ref(
  route.meta.sectionKeys?.some((k) =>
    Object.keys(userSettings.data).some((e) => e.includes(k))
  )
);
</script>

<style scoped>
:deep(.v-list-item-title) {
  line-height: 2.25rem;
}

:deep(.v-list-group__items .v-list-item:hover) {
  background-color: theme("colors.gray.100");
}

:deep(.v-list-item__append > .v-icon.toggle-icon) {
  transition: rotate 0.3s;
}

:deep(.v-list-item--active .v-list-item__append > .v-icon.toggle-icon) {
  rotate: 90deg;
}

:deep(.list-item-title--select) {
  line-height: 24px;
  padding-top: 14px;
  margin-bottom: 2px;
}

:deep(.v-list-group__items .v-list-item) {
  padding-inline-start: 2.5rem !important;
}

/* To prevent slider halo from clipping */
:deep(.v-list-item__content) {
  overflow: visible !important;
}

:deep(.list-item--slider) {
  display: flex;
  justify-content: space-between;
  overflow: visible;
  margin-right: 8px;
}
:deep(.v-slider) {
  transition: opacity 0.4s;
}
/* When hovering, dim other sliders, unless the slider is focused and showing its thumb message */
:deep(
  .v-list-group__items:hover .v-slider:not(:hover):not(.v-slider--focused)
) {
  opacity: 0.4;
}

a .v-list-item:hover {
  background-color: rgb(0 0 0 / 0.03);
}
</style>
