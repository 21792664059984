import { default as _91_46_46_46slug_9327h0m7KTDgMeta } from "/home/runner/work/dashboard/dashboard/pages/[...slug].vue?macro=true";
import { default as borrowsNXVK8W5P39Meta } from "/home/runner/work/dashboard/dashboard/pages/analytical-tools/borrows.vue?macro=true";
import { default as single_45security0OufkQYw1wMeta } from "/home/runner/work/dashboard/dashboard/pages/analytical-tools/single-security.vue?macro=true";
import { default as billing_45comparefBTG2zr7WhMeta } from "/home/runner/work/dashboard/dashboard/pages/billing-compare.vue?macro=true";
import { default as broker_45comparisonLII5CkRKyaMeta } from "/home/runner/work/dashboard/dashboard/pages/broker-analysis/broker-comparison.vue?macro=true";
import { default as broker_45focusM778F6zPtkMeta } from "/home/runner/work/dashboard/dashboard/pages/broker-analysis/broker-focus.vue?macro=true";
import { default as edit_45passwordvBAKhmAUv8Meta } from "/home/runner/work/dashboard/dashboard/pages/edit-password.vue?macro=true";
import { default as compare3JljPjK2LaMeta } from "/home/runner/work/dashboard/dashboard/pages/fee-schedule/compare.vue?macro=true";
import { default as _91id_93SmOwNVHFVtMeta } from "/home/runner/work/dashboard/dashboard/pages/fee-schedule/detail/[id].vue?macro=true";
import { default as overviewWgKpGJE9RUMeta } from "/home/runner/work/dashboard/dashboard/pages/fee-schedule/overview.vue?macro=true";
import { default as all_45positionsW2rMEqOC6IMeta } from "/home/runner/work/dashboard/dashboard/pages/financing-breakdown/all-positions.vue?macro=true";
import { default as cash_45collateral7BuOW8R8X3Meta } from "/home/runner/work/dashboard/dashboard/pages/financing-breakdown/cash-collateral.vue?macro=true";
import { default as portfolio_45add_45onI76RbIzTSkMeta } from "/home/runner/work/dashboard/dashboard/pages/financing-breakdown/portfolio-add-on.vue?macro=true";
import { default as shorts_45borrowsXvEyX47OldMeta } from "/home/runner/work/dashboard/dashboard/pages/financing-breakdown/shorts-borrows.vue?macro=true";
import { default as swapsrSrJjoNT6fMeta } from "/home/runner/work/dashboard/dashboard/pages/financing-breakdown/swaps.vue?macro=true";
import { default as indexMm2ZM7LfDiMeta } from "/home/runner/work/dashboard/dashboard/pages/index.vue?macro=true";
import { default as single_45securityLps9oVEEMVMeta } from "/home/runner/work/dashboard/dashboard/pages/market-data/single-security.vue?macro=true";
import { default as tablepk0LnKs8z1Meta } from "/home/runner/work/dashboard/dashboard/pages/market-data/table.vue?macro=true";
import { default as actions8d53t0Oj4QMeta } from "/home/runner/work/dashboard/dashboard/pages/optimizations/actions.vue?macro=true";
import { default as savings_45analysisX1hLnqwojMMeta } from "/home/runner/work/dashboard/dashboard/pages/optimizations/savings-analysis.vue?macro=true";
import { default as summaryw2j18U5vKVMeta } from "/home/runner/work/dashboard/dashboard/pages/optimizations/summary.vue?macro=true";
import { default as strategies_45demowv6Vj2BuFSMeta } from "/home/runner/work/dashboard/dashboard/pages/strategies-demo.vue?macro=true";
import { default as strategiesHQgZMxGO6SMeta } from "/home/runner/work/dashboard/dashboard/pages/strategies.vue?macro=true";
import { default as subscription_45needed_45_91title_93d6Kg8OsEEEMeta } from "/home/runner/work/dashboard/dashboard/pages/subscription-needed-[title].vue?macro=true";
import { default as testum8NHwgjN6Meta } from "/home/runner/work/dashboard/dashboard/pages/test.vue?macro=true";
import { default as longs_45shortsvgAOEJzUIXMeta } from "/home/runner/work/dashboard/dashboard/pages/trade-date-data/longs-shorts.vue?macro=true";
import { default as unmapped_45accounts7guPpXxA86Meta } from "/home/runner/work/dashboard/dashboard/pages/unmapped-accounts.vue?macro=true";
import { default as assumptionsIqAUq7YW4uMeta } from "/home/runner/work/dashboard/dashboard/pages/wallet/assumptions.vue?macro=true";
import { default as _91id_93hct3zeuEtpMeta } from "/home/runner/work/dashboard/dashboard/pages/wallet/detail/account/[id].vue?macro=true";
import { default as _91id_93NmUGpobgRlMeta } from "/home/runner/work/dashboard/dashboard/pages/wallet/detail/pb/[id].vue?macro=true";
import { default as output0OWtHO0CosMeta } from "/home/runner/work/dashboard/dashboard/pages/wallet/output.vue?macro=true";
import { default as stage_45summaryF6PQKey081Meta } from "/home/runner/work/dashboard/dashboard/pages/wallet/stage-summary.vue?macro=true";
export default [
  {
    name: "Page Not Found",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_9327h0m7KTDgMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/[...slug].vue")
  },
  {
    name: "Borrows",
    path: "/analytical-tools/borrows",
    meta: borrowsNXVK8W5P39Meta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/analytical-tools/borrows.vue")
  },
  {
    name: "Single Security",
    path: "/analytical-tools/single-security",
    meta: single_45security0OufkQYw1wMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/analytical-tools/single-security.vue")
  },
  {
    name: "Billing Compare",
    path: "/billing-compare",
    meta: billing_45comparefBTG2zr7WhMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/billing-compare.vue")
  },
  {
    name: "Broker Comparison",
    path: "/broker-analysis/broker-comparison",
    meta: broker_45comparisonLII5CkRKyaMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/broker-analysis/broker-comparison.vue")
  },
  {
    name: "Broker Focus",
    path: "/broker-analysis/broker-focus",
    meta: broker_45focusM778F6zPtkMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/broker-analysis/broker-focus.vue")
  },
  {
    name: "edit-password",
    path: "/edit-password",
    meta: edit_45passwordvBAKhmAUv8Meta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/edit-password.vue")
  },
  {
    name: "Fee Schedule: Compare",
    path: "/fee-schedule/compare",
    meta: compare3JljPjK2LaMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/fee-schedule/compare.vue")
  },
  {
    name: "Fee Schedule: Detail",
    path: "/fee-schedule/detail/:id()",
    meta: _91id_93SmOwNVHFVtMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/fee-schedule/detail/[id].vue")
  },
  {
    name: "Fee Schedule",
    path: "/fee-schedule/overview",
    meta: overviewWgKpGJE9RUMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/fee-schedule/overview.vue")
  },
  {
    name: "All Positions",
    path: "/financing-breakdown/all-positions",
    meta: all_45positionsW2rMEqOC6IMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/financing-breakdown/all-positions.vue")
  },
  {
    name: "Cash & Collateral",
    path: "/financing-breakdown/cash-collateral",
    meta: cash_45collateral7BuOW8R8X3Meta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/financing-breakdown/cash-collateral.vue")
  },
  {
    name: "Portfolio Add-on",
    path: "/financing-breakdown/portfolio-add-on",
    meta: portfolio_45add_45onI76RbIzTSkMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/financing-breakdown/portfolio-add-on.vue")
  },
  {
    name: "Shorts & Borrows",
    path: "/financing-breakdown/shorts-borrows",
    meta: shorts_45borrowsXvEyX47OldMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/financing-breakdown/shorts-borrows.vue")
  },
  {
    name: "Swaps",
    path: "/financing-breakdown/swaps",
    meta: swapsrSrJjoNT6fMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/financing-breakdown/swaps.vue")
  },
  {
    name: "Overview",
    path: "/",
    meta: indexMm2ZM7LfDiMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/index.vue")
  },
  {
    name: "Securities Lending Data: Single Security",
    path: "/market-data/single-security",
    meta: single_45securityLps9oVEEMVMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/market-data/single-security.vue")
  },
  {
    name: "Securities Lending Data",
    path: "/market-data/table",
    meta: tablepk0LnKs8z1Meta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/market-data/table.vue")
  },
  {
    name: "Actions",
    path: "/optimizations/actions",
    meta: actions8d53t0Oj4QMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/optimizations/actions.vue")
  },
  {
    name: "Savings Analysis",
    path: "/optimizations/savings-analysis",
    meta: savings_45analysisX1hLnqwojMMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/optimizations/savings-analysis.vue")
  },
  {
    name: "Optimizations Summary",
    path: "/optimizations/summary",
    meta: summaryw2j18U5vKVMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/optimizations/summary.vue")
  },
  {
    name: "Strategies demo",
    path: "/strategies-demo",
    meta: strategies_45demowv6Vj2BuFSMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/strategies-demo.vue")
  },
  {
    name: "Strategies",
    path: "/strategies",
    meta: strategiesHQgZMxGO6SMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/strategies.vue")
  },
  {
    name: "Subscription Needed",
    path: "/subscription-needed-:title()",
    meta: subscription_45needed_45_91title_93d6Kg8OsEEEMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/subscription-needed-[title].vue")
  },
  {
    name: "Test",
    path: "/test",
    meta: testum8NHwgjN6Meta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/test.vue")
  },
  {
    name: "Longs & Shorts",
    path: "/trade-date-data/longs-shorts",
    meta: longs_45shortsvgAOEJzUIXMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/trade-date-data/longs-shorts.vue")
  },
  {
    name: "Unmapped Accounts",
    path: "/unmapped-accounts",
    meta: unmapped_45accounts7guPpXxA86Meta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/unmapped-accounts.vue")
  },
  {
    name: "Wallet: Assumptions",
    path: "/wallet/assumptions",
    meta: assumptionsIqAUq7YW4uMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/wallet/assumptions.vue")
  },
  {
    name: "Wallet: Schedule Detail",
    path: "/wallet/detail/account/:id()",
    meta: _91id_93hct3zeuEtpMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/wallet/detail/account/[id].vue")
  },
  {
    name: "Wallet: PB Assumptions",
    path: "/wallet/detail/pb/:id()",
    meta: _91id_93NmUGpobgRlMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/wallet/detail/pb/[id].vue")
  },
  {
    name: "Wallet",
    path: "/wallet/output",
    meta: output0OWtHO0CosMeta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/wallet/output.vue")
  },
  {
    name: "Wallet: Stage Summary",
    path: "/wallet/stage-summary",
    meta: stage_45summaryF6PQKey081Meta || {},
    component: () => import("/home/runner/work/dashboard/dashboard/pages/wallet/stage-summary.vue")
  }
]