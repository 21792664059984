<template>
  <v-select
    v-bind="$attrs"
    v-model="modelValue"
    v-model:menu="menu"
    :item-title
    :item-value
    :items
    density="comfortable"
  >
    <template
      v-for="(slot, index) of slotNames"
      :key="index"
      #[slot]="slotProps"
    >
      <slot
        :name="slot"
        v-bind="slotProps"
      />
    </template>
  </v-select>
</template>

<script lang="ts" setup>
import type { ComponentProps } from "vue-component-type-helpers";
import { VSelect } from "vuetify/lib/components/index.mjs";

import { useTrackEvent } from "@/composables/useTrackEvent";

type VSelectProps = ComponentProps<typeof VSelect>;
const props = withDefaults(
  defineProps<{
    itemTitle?: VSelectProps["itemTitle"];
    itemValue?: VSelectProps["itemValue"];
    trackEvent: TrackEventProperties | undefined;
    items: VSelectProps["items"];
  }>(),
  {
    itemTitle: "title",
    itemValue: "value",
  }
);

const slots = useSlots();

// Assert type here to prevent errors in template
const slotNames = Object.keys(slots) as "message"[];

const modelValue = defineModel<VSelectProps["modelValue"] | undefined>({
  required: true,
});

const menu = defineModel<VSelectProps["menu"]>("menu", {
  required: false,
});

if (props.trackEvent) {
  const trackEventValue = computed(() => {
    return (
      props.items?.find(
        (item) =>
          item === modelValue.value ||
          item[props.itemValue as string] === modelValue.value
      )?.[props.itemTitle as string] ?? modelValue.value
    );
  });

  useTrackEvent().watch({
    value: trackEventValue,
    element: "select",
    ...props.trackEvent,
  });
}
</script>

<style scoped>
:deep(label) {
  transform: translateY(-12px) !important;
}
:deep(.v-select__selection-text) {
  font-size: 0.875rem;
}
</style>

<style>
.v-list-item {
  min-height: 2.5rem !important;
}
.v-list-item-title {
  font-size: 0.875rem !important;
}
</style>
