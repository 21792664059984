<template>
  <v-checkbox
    v-bind="$attrs"
    v-model="modelValue"
  >
    <template
      v-for="(slot, index) of slotNames"
      :key="index"
      #[slot]
    >
      <slot :name="slot" />
    </template>
  </v-checkbox>
</template>

<script lang="ts" setup>
import type { ComponentProps } from "vue-component-type-helpers";
import { VCheckbox } from "vuetify/lib/components/index.mjs";

type VCheckboxProps = ComponentProps<typeof VCheckbox>;
const props = defineProps<{
  trackEvent: TrackEventProperties | undefined;
}>();

const slots = useSlots();

// Assert type here to prevent errors in template
const slotNames = Object.keys(slots) as "default"[];

const modelValue = defineModel<VCheckboxProps["modelValue"]>({
  required: true,
});

if (props.trackEvent) {
  useTrackEvent().watch({
    value: modelValue,
    element: "checkbox",
    ...props.trackEvent,
  });
}
</script>

<style scoped>
.v-checkbox {
  --v-input-control-height: 48px;
}
:deep(label) {
  font-size: 0.875rem;
}
:deep(.v-icon) {
  --v-icon-size-multiplier: 0.875;
}
</style>
